import { useTranslation } from 'react-i18next';

import { StravaIcon } from '../icons/socialMedia';

interface StravaIndicatorProps {
  className?: string;
  size?: string;
}

const StravaIndicator = ({ className = '', size = '18px' }: StravaIndicatorProps) => {
  const { t } = useTranslation();
  return (
    <div className={`bg-white rounded-full px-1 ${className}`}>
      <div className="flex items-end space-x-1 hover:cursor-pointer font-bold py-[3px]">
        <StravaIcon fill="#FC5200" className={`h-[${size}] w-[${size}] mr-[-5px]`} />
        <span className="text-black text-xs lg:text-[.58rem]">{t('common:map')}</span>
      </div>
    </div>
  );
};

export default StravaIndicator;
